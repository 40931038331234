body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: black;
}

.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

canvas {
  object-fit: cover;
  width: 100%;
  min-height: 100vh;
  object-position: top center;
  margin: 0;
}

.logo {
  position: absolute;
  z-index: 2;
  cursor: pointer;
}
