* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 18px;
  font-family: "Museo", sans-serif;
}

.box {
  width: 480px;
  max-width: 100%;
  padding: 2rem;
  margin: 0;
}

@media screen and (min-width: 992px) {
  .box {
    margin: 4rem;
  }
}

.box .brand {
  width: 100%;
  margin: 0;
}

.box p {
  margin: 1rem 0;
  font-weight: 200;
  text-align: justify;
}

.illustration-container {
  overflow: hidden;
  margin: auto 0 0 auto;
  position: relative;
}

.illustration-wrapper {
  position: relative;
}

.zoom-controls {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 99;
}
.zoom-controls button {
  margin: 0.5rem 0;
  padding: 0.5rem;
  border: none;
  background: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  outline: none;
}
.asset-button {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000e;
  z-index: 98;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 1rem;
  pointer-events: none;
}

.modal__container {
  pointer-events: all;
  width: 100%;
  max-width: 500px;
  padding: 2rem;
  background: rgba(18, 35, 37, 0.83);
  color: #fff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media screen and (min-width: 992px) {
  .modal {
    padding: 4rem 0;
  }
}

.modal__content {
  width: 100%;
  max-width: 100%;
}

.modal__header {
  position: relative;
  max-width: 100%;
}

.modal__body {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.modal__close {
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  padding: 0.5rem;
  border: none;
  background: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  outline: none;
}

.modal__close img {
  width: 24px;
}

.modal__container h2 {
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
}

.modal__container p {
  font-size: 1.25rem;
  font-weight: 300;
  text-align: center;
}

.modal__link {
  display: block;
  margin: 1rem auto;
  width: auto;
  padding: 0.45rem 1rem 0.55rem;
  color: lime;
  border: 1px solid #0f02;
  border-radius: 2rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  transition: all 0.2s ease-in-out;
}
.modal__link:hover {
  background: lime;
  color: black;
}

.biner-logo button,
.entraste-logo button,
.blocktracker-logo button,
.trokor-logo button,
.kicknerds-logo button,
.millsonic-logo button {
  cursor: pointer;
}

.App.biner-modal .biner-building,
.App.biner-modal .biner-logo,
.App.entraste-modal .entraste-building,
.App.entraste-modal .entraste-logo,
.App.blocktracker-modal .blocktracker-building,
.App.blocktracker-modal .blocktracker-logo,
.App.trokor-modal .trokor-building,
.App.trokor-modal .trokor-logo,
.App.kicknerds-modal .kicknerds-building,
.App.kicknerds-modal .kicknerds-logo,
.App.millsonic-modal .millsonic-building,
.App.millsonic-modal .millsonic-logo {
  z-index: 100 !important;
  pointer-events: none;
}

.splash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: 0;
  z-index: 200;
  background: #0003;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.splash .brand {
  width: 500px;
  max-width: 100%;
}

.contact_form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contact_formgroup {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contact_formgroup label {
  color: white;
  font-size: 0.875rem;
  margin-left: 0.75rem;
  opacity: 0.25;
}

.contact_formgroup.required label {
  opacity: 0.5;
}

.contact_formgroup input,
.contact_formgroup textarea {
  color: white;
  border: 1px solid;
  background: transparent;
  margin: 0.125rem 0 1rem;
  padding: 0.5rem 0.75rem;
  font-size: 1.125rem;
  border-radius: 0.5rem;
}

.contact_button {
  margin-left: auto;
  width: auto;
  padding: 0.45rem 1rem 0.45rem;
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  border-radius: 2rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.modal__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal__button {
  color: white;
  background: transparent;
  border: none;
  font-weight: bold;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
}

.modal__button img {
  width: 18px;
}
